import { ReactNode } from "react";
import styled from "styled-components";

export const TableHeader = styled.thead`
  background-color: var(--color-primary);
  color: var(--color-white);
  text-align: left;
`;

export const TableRow = styled.tr<{ row: number }>`
  background-color: ${(props) =>
    props.row % 2 === 1
      ? "var(--color-grey-light)"
      : "var(--color-grey-super-light)"};
  color: black;
`;

export const TableDiv = ({ children }: { children: ReactNode }) => {
  return (
    <div className="px-3 w-full pt-10 text-white md:px-10">
      <div className="overflow-x-auto">
        <table className="rounded w-full">{children}</table>
      </div>
    </div>
  );
};
