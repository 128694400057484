import { useMutation } from "@tanstack/react-query";

import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { medicalDataApi } from "../../../api";
import { MedicalDataUpdateDTO } from "../../../api/data-contracts";
import Main from "../../common/Main";
import MedicalDataEditForm from "./MedicalDataEditForm";
import { TableType } from "../../../api/ExtraEnums";

const MedicalDataEditPage = () => {
  const { type, id } = useParams();
  const navigate = useNavigate();
  const { t, ready: localeReady } = useTranslation(["tables"]);

  let { mutate: onSubmit, isLoading: isLoadingSubmit } = useMutation({
    mutationKey: ["medicalDataUpdate", id, type],
    mutationFn: (data: MedicalDataUpdateDTO) => {
      data.id = id;
      data.tableType = type;
      return medicalDataApi.updateMedicalData(data);
    },
    onSuccess: () => {
      navigate(`/tables`);
    },
  });

  if (id === undefined) {
    return <p>Error: no id</p>;
  }

  if (type === undefined) {
    return <p>Error: no table type</p>;
  }

  const tableType = type as TableType;

  const isLoading = isLoadingSubmit || !localeReady;

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Main>
      <div className="flex items-center max-w-screen-xl">
        <div className="p-10">
          <h3 className="mb-5">
            {isLoading ? <Skeleton width="8ch" /> : t("title-edit")}
          </h3>
          <MedicalDataEditForm
            id={id}
            onSubmit={onSubmit}
            tableType={tableType}
          />
        </div>
      </div>
    </Main>
  );
};

export default MedicalDataEditPage;
