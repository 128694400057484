import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usersApi } from "../../../api";
import { UserInfoDTO } from "../../../api/data-contracts";
import { useAuth } from "../../../auth/AuthContext";

const NumOfRecords = () => {
  const { t, ready } = useTranslation("profile");
  const notReady = !ready;

  const auth = useAuth();
  const [userInfo, setUserInfo] = useState<UserInfoDTO | null>(null);

  const loadUserInfo = useCallback(async () => {
    if (auth.userInfo) {
      try {
        const resp = await usersApi.findUserById(auth.userInfo!.user!.id!);
        const newUserInfo = resp.data;
        setUserInfo(newUserInfo);
      } catch (error) {
        setUserInfo(null);
      }
    }
  }, [auth.userInfo, setUserInfo]);

  useEffect(() => {
    loadUserInfo();
  }, [auth, loadUserInfo]);

  if (notReady) {
    return <>Loading</>;
  }

  const total = `${t("total-1")} ${userInfo?.numOfRecords ?? "-"} ${t(
    "total-2"
  )}`;
  return (
    <div className="px-3 md:px-14">
      <h4 className="primary">{t("records-title")}</h4>
      <p className="mt-2">{total}</p>
    </div>
  );
};

export default NumOfRecords;
