import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PhotoDetail from "../../records/photo/PhotoDetail";

const StyledTile = styled.div<{ even: string }>`
  align-items: center;
  background-color: ${(props) =>
    props.even === "true" ? "white" : "var(--color-bg-blue)"};
`;

const StyledTitle = styled.h2`
  color: var(--color-primary);
`;

const StyledText = styled.p`
  color: var(--color-blue);
  max-width: 600px;
`;

interface InfoDivProps {
  index: number;
}

const InfoDiv = ({ index }: InfoDivProps) => {
  const { t, ready } = useTranslation("main", { keyPrefix: "info" });
  const notReady = !ready;

  if (notReady) {
    return <></>;
  }

  const imageFirst = index % 2 === 0;
  const img = (
    <div className="mx-auto mb-4 max-w-[600px] pt-8 md:pt-0 w-full md:w-1/2 px-3">
      <PhotoDetail url={`images/info/${index + 1}.png`}></PhotoDetail>
    </div>
  );
  const textDiv = (
    <div className="flex items-center justify-center w-full md:w-1/2">
      <div className="px-3 md:px-10">
        <StyledTitle>{t(`title-${index}`)}</StyledTitle>
        <StyledText className="mt-5">{t(`desc-${index}`)}</StyledText>
      </div>
    </div>
  );

  return (
    <StyledTile
      even={index % 2 === 0 ? "true" : "false"}
      className={`py-10 block md:flex px-3 md:px-10 ${
        imageFirst ? "md:flex-row-reverse" : ""
      }`}
    >
      {textDiv}
      {img}
    </StyledTile>
  );
};

export default InfoDiv;
