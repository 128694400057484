import { useQuery } from "@tanstack/react-query";

import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import Main from "../../common/Main";
import RecordCreateForm from "./RecordCreateForm";
import { medicalDataApi } from "../../../api";
import { MedicalDataDTO } from "../../../api/data-contracts";
import { TableType } from "../../../api/ExtraEnums";
import BackButton from "../../common/BackButton";

const RecordCreatePage = () => {
  const { t, ready: localeReady } = useTranslation(["records"]);

  const {
    isLoading: isLoadingDiagnosis,
    isError: isErrorDiagnosis,
    data: diagnosis,
  } = useQuery<MedicalDataDTO[], AxiosError>({
    queryKey: ["diagnosis"],
    queryFn: () =>
      medicalDataApi
        .findAllMedicalData({ tableType: TableType.DIAGNOSIS })
        .then((resp) => resp.data),
  });

  const {
    isLoading: isLoadingDiscipline,
    isError: isErrorDiscipline,
    data: disciplines,
  } = useQuery<MedicalDataDTO[], AxiosError>({
    queryKey: ["discipline"],
    queryFn: () =>
      medicalDataApi
        .findAllMedicalData({ tableType: TableType.DISCIPLINE })
        .then((resp) => resp.data),
  });

  const {
    isLoading: isLoadingGender,
    isError: isErrorGender,
    data: genders,
  } = useQuery<MedicalDataDTO[], AxiosError>({
    queryKey: ["gender"],
    queryFn: () =>
      medicalDataApi
        .findAllMedicalData({ tableType: TableType.GENDER })
        .then((resp) => resp.data),
  });

  const {
    isLoading: isLoadingLocation,
    isError: isErrorLocation,
    data: locations,
  } = useQuery<MedicalDataDTO[], AxiosError>({
    queryKey: ["location"],
    queryFn: () =>
      medicalDataApi
        .findAllMedicalData({ tableType: TableType.LOCATION })
        .then((resp) => resp.data),
  });

  const {
    isLoading: isLoadingSurgery,
    isError: isErrorSurgery,
    data: surgeries,
  } = useQuery<MedicalDataDTO[], AxiosError>({
    queryKey: ["surgery"],
    queryFn: () =>
      medicalDataApi
        .findAllMedicalData({ tableType: TableType.SURGERY })
        .then((resp) => resp.data),
  });

  const loadings = [
    isLoadingDiagnosis,
    isLoadingDiscipline,
    isLoadingGender,
    isLoadingLocation,
    isLoadingSurgery,
  ];
  const errors = [
    isErrorDiagnosis,
    isErrorDiscipline,
    isErrorGender,
    isErrorLocation,
    isErrorSurgery,
  ];

  const isLoading = loadings.some((loading) => loading);
  const isError = errors.some((hasError) => hasError);

  if (isLoading || !localeReady) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Contains Erros...</p>;
  }

  return (
    <Main>
      <div className="flex items-center max-w-screen-xl">
        <div className="p-10">
          <div className="flex mb-5">
            <BackButton />
            <h3 className="ml-16">
              {isLoading ? <Skeleton width="8ch" /> : t("create.title")}
            </h3>
          </div>
          <RecordCreateForm
            diagnosis={diagnosis!}
            disciplines={disciplines!}
            genders={genders!}
            locations={locations!}
            surgeries={surgeries!}
            submitTitle={"create.button"}
          />
        </div>
      </div>
    </Main>
  );
};

export default RecordCreatePage;
