import { useTranslation } from "react-i18next";
import { useAuth } from "../../../auth/AuthContext";
import Button from "../../common/Button";

const ProfileInfo = () => {
  const auth = useAuth();
  const { t, ready } = useTranslation(["profile"]);
  const notReady = !ready;

  const userInfo = auth.userInfo;
  if (!userInfo || notReady) {
    return <p>Loading...</p>;
  }
  const user = userInfo.user!;

  const signOut = () => {
    auth.logout();
  };

  const lougoutBtn = (
    <Button
      onClick={() => {
        signOut();
      }}
    >
      {t("sign-out")}
    </Button>
  );

  return (
    <div className="flex justify-between w-full py-10 px-3 md:px-14">
      <div>
        <h4 className="primary">{user.firstName! + " " + user.secondName}</h4>
        <p className="mt-2">{t(`role-enum.${user.role}`) + ` (${user.uco})`}</p>
      </div>
      {lougoutBtn}
    </div>
  );
};
export default ProfileInfo;
