import { useTranslation } from "react-i18next";
import Main from "../common/Main";
import TitleDiv from "../common/TitleDiv";
import NumOfRecords from "./components/NumOfRecords";
import ProfileInfo from "./components/ProfileInfo";

const ProfilePage = () => {
  const { t, ready } = useTranslation(["profile"]);

  const notReady = !ready;

  return (
    <Main>
      <TitleDiv title={notReady ? "" : t("profile-title")} />
      <ProfileInfo />
      <NumOfRecords />
    </Main>
  );
};

export default ProfilePage;
