function deepFind(obj: any, path: string) {
  let paths = path.split(".");
  let current = obj;

  for (let i = 0; i < paths.length; ++i) {
    if (current[paths[i]] === undefined) {
      return undefined;
    }
    current = current[paths[i]];
  }
  return current;
}

export default deepFind;
