import { useMutation } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { medicalDataApi } from "../../../api";
import { MedicalDataCreateDTO } from "../../../api/data-contracts";
import Button from "../../common/Button";
import { Field, Form } from "../../common/form";
import FormDiv from "../../common/form/FormDiv";
import { TableType } from "../../../api/ExtraEnums";
import Main from "../../common/Main";
import EditingTableInfo from "../edit/EditingTableInfo";

const RecordCreateForm = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const { t, ready } = useTranslation(["records"]);
  const isLoadingTranslation = !ready;

  const methods = useForm<MedicalDataCreateDTO>();
  const { handleSubmit } = methods;

  let { mutate: onSubmit } = useMutation({
    mutationKey: ["medicalDataCreate"],
    mutationFn: async (data: MedicalDataCreateDTO) => {
      data["tableType"] = type;
      return await medicalDataApi.createMedicalData(data);
    },
    onSuccess: () => {
      navigate(`/tables`);
    },
  });

  if (type === undefined) {
    return <p>Error: no table type</p>;
  }

  if (isLoadingTranslation) {
    return (
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit((d) => onSubmit(d))} t={t}>
          <Field.Text isLoading />
          <Field.Number isLoading />
          <FormDiv>
            <Field.Number isLoading />
            <Field.Dropdown isLoading />
            <Field.Dropdown isLoading />
            <Field.Dropdown isLoading />
          </FormDiv>
          <Field.TextArea isLoading />

          <Button>
            <Skeleton width="8ch" />
          </Button>
        </Form>
      </FormProvider>
    );
  } else {
    return (
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit((d) => onSubmit(d))} t={t}>
          <EditingTableInfo t={t} tableType={type} />
          <Field.Text<MedicalDataCreateDTO>
            field="nameCS"
            localeKey="headers.nameCS"
          />
          <Field.Text<MedicalDataCreateDTO>
            field="nameEN"
            localeKey="headers.nameEN"
          />
          <Button type="submit">{t("create.button")}</Button>
        </Form>
      </FormProvider>
    );
  }
};

export default RecordCreateForm;
