import { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainPage from "./modules/main/MainPage";
import LoginPage from "./modules/login/LoginPage";
import ProfilePage from "./modules/profile/ProfilePage";
import { AuthProvider } from "./auth/AuthContext";
import RequiresAuth from "./auth/RequiresAuth";
import RecordsPage from "./modules/records/list/RecordsPage";
import RecordCreatePage from "./modules/records/create/RecordCreatePage";
import RecordDetailPage from "./modules/records/detail/RecordDetailPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import UsersPage from "./modules/users/list/UsersPage";
import UserEditPage from "./modules/users/edit/UserEditPage";
import RecordEditPage from "./modules/records/edit/RecordEditPage";
import TablesPage from "./modules/tables/list/TablesPage";
import MedicalDataCreatePage from "./modules/tables/create/MedicalDataCreatePage";
import MedicalDataEditPage from "./modules/tables/edit/MedicalDataEditPage";
import RequiresAuthRole from "./auth/RequiresAuthRole";
import { RoleTypeEnum } from "./api/ExtraEnums";
import NotFoundPage from "./modules/not-found/NotFoundPage";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <Suspense fallback="Loading...">
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="login" element={<LoginPage />} />

              <Route element={<RequiresAuth redirectTo="/login" />}>
                {/* Any role */}
                <Route path="profile" element={<ProfilePage />} />
                <Route path="records" element={<RecordsPage />} />
                <Route path="record/:id" element={<RecordDetailPage />} />

                {/* Teacher and Admin */}
                <Route
                  element={
                    <RequiresAuthRole
                      redirectTo="/records"
                      acceptedRoles={[RoleTypeEnum.ADMIN, RoleTypeEnum.TEACHER]}
                    />
                  }
                >
                  <Route path="record/create" element={<RecordCreatePage />} />
                  <Route path="record/edit/:id" element={<RecordEditPage />} />
                </Route>

                {/* Admin */}
                <Route
                  element={
                    <RequiresAuthRole
                      redirectTo="/records"
                      acceptedRoles={[RoleTypeEnum.ADMIN]}
                    />
                  }
                >
                  <Route path="users" element={<UsersPage />} />
                  <Route path="user/edit/:id" element={<UserEditPage />} />
                  <Route path="tables" element={<TablesPage />} />
                  <Route
                    path="table/create/:type"
                    element={<MedicalDataCreatePage />}
                  />
                  <Route
                    path="table/edit/:type/:id"
                    element={<MedicalDataEditPage />}
                  />
                </Route>
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Suspense>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
