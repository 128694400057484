import { useTranslation } from "react-i18next";
import TitleDiv from "../../common/TitleDiv";
import UsersSection from "./components/UsersSection";
import Main from "../../common/Main";

const UsersPage = () => {
  const { t, ready } = useTranslation("users", { keyPrefix: "list" });
  const notReady = !ready;

  if (notReady) {
    return <></>;
  }

  return (
    <Main>
      <TitleDiv title={t("title")} />
      <UsersSection />
    </Main>
  );
};

export default UsersPage;
