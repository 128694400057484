import { useQuery } from "@tanstack/react-query";

import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";

import { medicalDataApi, recordApi } from "../../../api";
import { TableType } from "../../../api/ExtraEnums";
import { MedicalDataDTO } from "../../../api/data-contracts";
import Icon from "../../common/Icon";
import Main from "../../common/Main";
import RecordEditForm from "./RecordEditForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import BackButton from "../../common/BackButton";

const RecordEditPage = () => {
  const { id } = useParams();
  const { t, ready: localeReady } = useTranslation(["records"]);
  const navigate = useNavigate();
  const {
    isLoading: isLoadingDiagnosis,
    isError: isErrorDiagnosis,
    data: diagnosis,
  } = useQuery<MedicalDataDTO[], AxiosError>({
    queryKey: ["diagnosis"],
    queryFn: () =>
      medicalDataApi
        .findAllMedicalData({ tableType: TableType.DIAGNOSIS })
        .then((resp) => resp.data),
  });

  const {
    isLoading: isLoadingDiscipline,
    isError: isErrorDiscipline,
    data: disciplines,
  } = useQuery<MedicalDataDTO[], AxiosError>({
    queryKey: ["discipline"],
    queryFn: () =>
      medicalDataApi
        .findAllMedicalData({ tableType: TableType.DISCIPLINE })
        .then((resp) => resp.data),
  });

  const {
    isLoading: isLoadingGender,
    isError: isErrorGender,
    data: genders,
  } = useQuery<MedicalDataDTO[], AxiosError>({
    queryKey: ["gender"],
    queryFn: () =>
      medicalDataApi
        .findAllMedicalData({ tableType: TableType.GENDER })
        .then((resp) => resp.data),
  });

  const {
    isLoading: isLoadingLocation,
    isError: isErrorLocation,
    data: locations,
  } = useQuery<MedicalDataDTO[], AxiosError>({
    queryKey: ["location"],
    queryFn: () =>
      medicalDataApi
        .findAllMedicalData({ tableType: TableType.LOCATION })
        .then((resp) => resp.data),
  });

  const {
    isLoading: isLoadingSurgery,
    isError: isErrorSurgery,
    data: surgeries,
  } = useQuery<MedicalDataDTO[], AxiosError>({
    queryKey: ["surgery"],
    queryFn: () =>
      medicalDataApi
        .findAllMedicalData({ tableType: TableType.SURGERY })
        .then((resp) => resp.data),
  });

  const loadings = [
    isLoadingDiagnosis,
    isLoadingDiscipline,
    isLoadingGender,
    isLoadingLocation,
    isLoadingSurgery,
  ];
  const errors = [
    isErrorDiagnosis,
    isErrorDiscipline,
    isErrorGender,
    isErrorLocation,
    isErrorSurgery,
  ];

  const isLoading = loadings.some((loading) => loading);
  const isError = errors.some((hasError) => hasError);

  if (id === undefined) {
    return <p>Error: no id</p>;
  }

  if (isLoading || !localeReady) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Contains Erros...</p>;
  }

  const deleteRecord = async () => {
    await recordApi.removeRecordById(id);
    navigate("/records");
  };

  return (
    <Main>
      <div className="flex items-center max-w-screen-xl">
        <div className="p-10">
          <div className="flex justify-between">
            <BackButton />
            <h3>{isLoading ? <Skeleton width="8ch" /> : t("edit.title")}</h3>
            <Icon
              className="mt-4"
              src="/images/icons/trash.png"
              alt="trash icon"
              onClick={() => deleteRecord()}
            />
          </div>
          <RecordEditForm
            id={id}
            diagnosis={diagnosis!}
            disciplines={disciplines!}
            genders={genders!}
            locations={locations!}
            surgeries={surgeries!}
          />
        </div>
      </div>
    </Main>
  );
};

export default RecordEditPage;
