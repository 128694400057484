import axios from "axios";
import { Record } from "./Record";
import { User } from "./User";
import { HttpClient } from "./http-client";
import { MedicalData } from "./MedicalData";
import { FileApi } from "./FileApi";
import { AuthApi } from "./AuthApi";

const api = process.env.REACT_APP_SYMFONY_URL;

console.log("REACT_APP_SYMFONY_URL", api);

const axiosClient = axios.create({
  baseURL: api,
});
const authApi = new AuthApi();
const medicalDataApi = new MedicalData();
const recordApi = new Record();
const usersApi = new User();
const fileApi = new FileApi();

const apis: HttpClient[] = [
  authApi,
  medicalDataApi,
  recordApi,
  usersApi,
  fileApi,
];

for (const api of apis) {
  api.instance = axiosClient;
}

export { authApi, medicalDataApi, recordApi, usersApi, fileApi, axiosClient };
