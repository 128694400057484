/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { CodeDTO, PageInputDTO, PageResultDTO, UserDTO, UserInfoDTO, UserUpdateDTO } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class User<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags User
   * @name RedirectToMuni
   * @request GET:/api/user/redirect-to-provider
   */
  redirectToMuni = (params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/api/user/redirect-to-provider`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name HandleCallback
   * @request POST:/api/user/handle-provider-callback
   */
  handleCallback = (data: CodeDTO, params: RequestParams = {}) =>
    this.request<CodeDTO, any>({
      path: `/api/user/handle-provider-callback`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name GetCurrentUser
   * @request GET:/api/user/get-current-user
   */
  getCurrentUser = (params: RequestParams = {}) =>
    this.request<UserInfoDTO, any>({
      path: `/api/user/get-current-user`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name FindUsers
   * @request POST:/api/user/
   */
  findUsers = (data: PageInputDTO, params: RequestParams = {}) =>
    this.request<PageResultDTO, any>({
      path: `/api/user/`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UpdateUser
   * @request POST:/api/user/update
   */
  updateUser = (data: UserUpdateDTO, params: RequestParams = {}) =>
    this.request<UserDTO, any>({
      path: `/api/user/update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name FindUserById
   * @request GET:/api/user/findById/{id}
   */
  findUserById = (id: string, params: RequestParams = {}) =>
    this.request<UserInfoDTO, any>({
      path: `/api/user/findById/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name RemoveUserById
   * @request DELETE:/api/user/delete/{id}
   */
  removeUserById = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/user/delete/${id}`,
      method: "DELETE",
      ...params,
    });
}
