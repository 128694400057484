import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { medicalDataApi } from "../../../../api";
import { TableType } from "../../../../api/ExtraEnums";
import {
  MedicalDataDTO,
  MedicalDataDeleteDTO,
} from "../../../../api/data-contracts";
import { sortingFunc } from "../../../../utils/Converter";
import { EditTableDiv } from "../../../common/table/EditTable";
import MedicalDataRow from "./MedicalDataRow";
import { TableHeader } from "../../../common/table/Table";

interface Props {
  type: TableType;
}

const TableDataLoader = ({ type }: Props) => {
  const { t, ready, i18n } = useTranslation(["records"]);
  const [uniqueLoader, setUniqueLoader] = useState(1);
  const navigate = useNavigate();

  const { isLoading, error, data } = useQuery<MedicalDataDTO[], AxiosError>({
    queryKey: ["medicalData", type, uniqueLoader],
    queryFn: () =>
      medicalDataApi.findAllMedicalData({ tableType: type }).then((resp) => {
        return resp.data;
      }),
  });

  if (isLoading || !ready) {
    return <p></p>;
  }
  if (error) {
    return <p>{error.message}</p>;
  }

  const dataOrdered = sortingFunc(data, i18n.language) as MedicalDataDTO[];

  const onDelete = async (dataForDelete: MedicalDataDTO) => {
    const deleteDataDTO: MedicalDataDeleteDTO = {
      ...dataForDelete,
      tableType: type.toString(),
    };
    await medicalDataApi.removeMedicalData(deleteDataDTO);
    increaseLoader();
  };

  const onEdit = (dataForUpdate: MedicalDataDTO) => {
    navigate(`/table/edit/${type}/${dataForUpdate.id}`);
  };

  const increaseLoader = () => {
    setUniqueLoader(uniqueLoader + 1);
  };
  return (
    <EditTableDiv>
      <TableHeader>
        <tr>
          <th>{t("headers.nameCS")}</th>
          <th>{t("headers.nameEN")}</th>
          <th>{t("headers.edit")}</th>
          <th>{t("headers.delete")}</th>
        </tr>
      </TableHeader>
      <tbody>
        {dataOrdered.map((d, i) => (
          <MedicalDataRow
            key={i}
            rowNum={i + 2}
            data={d}
            t={t}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        ))}
      </tbody>
    </EditTableDiv>
  );
};

export default TableDataLoader;
