/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MedicalDataCreateDTO,
  MedicalDataDTO,
  MedicalDataDeleteDTO,
  MedicalDataUpdateDTO,
  TableTypeDTO,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class MedicalData<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags MedicalData
   * @name FindAllMedicalData
   * @request POST:/api/medical_data/
   * @secure
   */
  findAllMedicalData = (data: TableTypeDTO, params: RequestParams = {}) =>
    this.request<MedicalDataDTO[], any>({
      path: `/api/medical_data/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MedicalData
   * @name FindMedicalData
   * @request GET:/api/medical_data/findById/{type}/{id}
   */
  findMedicalData = (type: string, id: string, params: RequestParams = {}) =>
    this.request<MedicalDataDTO, any>({
      path: `/api/medical_data/findById/${type}/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MedicalData
   * @name CreateMedicalData
   * @request POST:/api/medical_data/create
   */
  createMedicalData = (data: MedicalDataCreateDTO, params: RequestParams = {}) =>
    this.request<MedicalDataDTO, any>({
      path: `/api/medical_data/create`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MedicalData
   * @name UpdateMedicalData
   * @request POST:/api/medical_data/update
   */
  updateMedicalData = (data: MedicalDataUpdateDTO, params: RequestParams = {}) =>
    this.request<MedicalDataDTO, any>({
      path: `/api/medical_data/update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MedicalData
   * @name RemoveMedicalData
   * @request POST:/api/medical_data/delete
   */
  removeMedicalData = (data: MedicalDataDeleteDTO, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/medical_data/delete`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
