import DicomMyViewer from "../../dicom/DicomMyViewer";

interface Props {
  url: string;
}
const DicomDetail = ({ url }: Props) => {
  return <DicomMyViewer requestType={"dicomweb"} url={url} />;
};

export default DicomDetail;
