import { MedicalDataDTO } from "../api/data-contracts";

export const convertInstantToDate = (date?: string) => {
  if (!date) {
    return "-";
  }
  return date.replaceAll("T", " ").slice(0, 16);
};

export const translateName = (
  medicalData: MedicalDataDTO,
  language: string
): string => {
  if (language === "cs") {
    return medicalData.nameCS!;
  } else if (language === "en") {
    return medicalData.nameEN!;
  }
  return "unknown language (src/utils/Converter)";
};

export const roundUp = (number: number) => {
  const decimalPlaces = 2;
  return Number(number.toFixed(decimalPlaces));
};

export const sortingFunc = (array: any, language: string) => {
  return array.sort((a: any, b: any) => {
    const nameA = translateName(a, language);
    const nameB = translateName(b, language);
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};
