import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Link from "../../../common/Link";
import { RecordDTO } from "../../../../api/data-contracts";
import { translateName } from "../../../../utils/Converter";
import { TableRow } from "../../../common/table/Table";
import { useAuth } from "../../../../auth/AuthContext";
import { RoleTypeEnum } from "../../../../api/ExtraEnums";

interface RecordRowProps {
  rowNum: number;
  record: RecordDTO;
}

const RecordRow = ({ record, rowNum }: RecordRowProps) => {
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const { t, ready, i18n } = useTranslation("records");
  const notReady = !ready;

  if (notReady) {
    return <></>;
  }

  const onEditClick = () => {
    navigate("/record/edit/" + record.id);
  };

  const onViewClick = () => {
    navigate("/record/" + record.id);
  };

  const role = userInfo?.user?.role;

  return (
    <TableRow row={rowNum}>
      <td>{i18n.language === "en" ? record.titleEN : record.titleCS}</td>
      <td>{translateName(record.diagnosis!, i18n.language)}</td>
      <td>{translateName(record.surgery!, i18n.language)}</td>
      <td>{record.creationDate}</td>
      {(role === RoleTypeEnum.ADMIN ||
        (role === RoleTypeEnum.TEACHER &&
          userInfo?.user?.id === record.user?.id)) && (
        <td>
          <Link onClick={onEditClick}>{t("headers.edit-button")}</Link>
        </td>
      )}

      <td>
        <Link onClick={onViewClick}>{t("headers.view-button")}</Link>
      </td>
    </TableRow>
  );
};

export default RecordRow;
