import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TableType } from "../../../api/ExtraEnums";
import Button from "../../common/Button";
import Dropdown from "../../common/Dropdown";
import Main from "../../common/Main";
import TitleDiv from "../../common/TitleDiv";
import TableDataLoader from "./components/TableDataLoader";

const TablesPage = () => {
  const navigate = useNavigate();
  const storedTableType = localStorage.getItem("tables_table_type");
  console.log(storedTableType, "storedTableType");

  const { t: tRecords, ready: readyRecords } = useTranslation(["records"]);
  const { t: tTables, ready: readyTables } = useTranslation(["tables"]);
  const [tableType, setTableType] = useState<TableType>(
    storedTableType !== null && storedTableType !== undefined
      ? (storedTableType as TableType)
      : TableType.DIAGNOSIS
  );

  const options = Object.values(TableType);

  const onCreate = () => {
    navigate("/table/create/" + tableType);
  };
  const changeTableType = (newTableType: TableType) => {
    localStorage.setItem("tables_table_type", newTableType);
    setTableType(newTableType);
  };
  return (
    <Main>
      <TitleDiv title={!readyTables ? "" : tTables("title")} />
      <div className="px-3 md:px-14">
        {readyRecords && readyTables && (
          <div className="flex justify-between lg:w-1/2">
            <Dropdown
              options={options}
              translations={options.map((o) =>
                tRecords("table-type-enum." + o)
              )}
              initValue={tableType}
              onChange={(newValue) => changeTableType(newValue as TableType)}
              title={tTables("dropdown-title")}
            />
            <Button className="mt-8" onClick={onCreate}>
              {tTables("title-create")}
            </Button>
          </div>
        )}
        <TableDataLoader type={tableType} />
      </div>
    </Main>
  );
};

export default TablesPage;
