import { useState } from "react";

import styled from "styled-components";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const StyledPhoto = styled.img`
  object-fit: scale-down;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

interface PhotoDetailProps {
  url: string;
}

const PhotoDetail = ({ url }: PhotoDetailProps) => {
  const [showLightbox, setShowLightbox] = useState(false);

  if (!url) {
    return <p>No image</p>;
  }

  return (
    <>
      <StyledPhoto src={url} onClick={() => setShowLightbox(true)} />
      <Lightbox
        open={showLightbox}
        close={() => setShowLightbox(false)}
        slides={[{ src: url }]}
        carousel={{ finite: true }}
      />
    </>
  );
};

export default PhotoDetail;
