import InfoDiv from "./InfoDiv";

const InfoSection = () => {
  return (
    <div>
      <InfoDiv index={0} />
      <InfoDiv index={1} />
    </div>
  );
};

export default InfoSection;
