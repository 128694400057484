import Button from "./Button";

interface PageSwitcherProps {
  currentPage: number;
  totalPages: number;
  changePage: (newPageNum: number) => void;
}
const PageSwitcher = ({
  currentPage,
  totalPages,
  changePage,
}: PageSwitcherProps) => {
  return (
    <div className="flex w-full justify-center mt-3">
      <Button
        onClick={() => changePage(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {"<"}
      </Button>
      <p className="h-full px-3 mt-2 justify-content-center">{`${currentPage} / ${totalPages}`}</p>
      <Button
        onClick={() => changePage(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        {">"}
      </Button>
    </div>
  );
};

export default PageSwitcher;
