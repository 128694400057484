import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { usersApi } from "../../../api";
import { RoleTypeEnum } from "../../../api/ExtraEnums";
import { UserInfoDTO, UserUpdateDTO } from "../../../api/data-contracts";
import Button from "../../common/Button";
import { Field, Form } from "../../common/form";
import FormDiv from "../../common/form/FormDiv";
import DataRow from "../../records/detail/components/DataRow";
import UserInfoDiv from "./UserInfoDiv";

type Props = {
  id: string;
  onSubmit: (data: UserUpdateDTO) => void;
};

const UserEditForm = ({ onSubmit, id }: Props) => {
  const { t, ready } = useTranslation(["users"]);
  const isLoadingTranslation = !ready;

  const methods = useForm<UserUpdateDTO>();
  const { handleSubmit, setValue } = methods;
  const [userInfo, setUserInfo] = useState<UserInfoDTO | undefined>(undefined);

  const loadUser = useCallback(async () => {
    const newUser = await usersApi.findUserById(id).then((resp) => {
      const data = resp.data;
      if (data === undefined || data.user === undefined) {
        return null;
      }
      setValue("role", data.user!.role);
      return data;
    });
    if (newUser) {
      setUserInfo(newUser);
    }
  }, [id, setValue, setUserInfo]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  if (isLoadingTranslation || !userInfo) {
    return (
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} t={t}>
          <Field.Text isLoading />
          <Field.Number isLoading />
          <FormDiv>
            <Field.Number isLoading />
            <Field.Dropdown isLoading />
            <Field.Dropdown isLoading />
            <Field.Dropdown isLoading />
          </FormDiv>
          <Field.TextArea isLoading />

          <Button>
            <Skeleton width="8ch" />
          </Button>
        </Form>
      </FormProvider>
    );
  } else {
    return (
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} t={t}>
          <UserInfoDiv userInfo={userInfo} />
          <Field.Dropdown<UserUpdateDTO>
            field="role"
            localeKey="role"
            options={Object.values(RoleTypeEnum)}
          />
          <Button className="mt-8" type="submit">
            {t("edit.button")}
          </Button>
        </Form>
      </FormProvider>
    );
  }
};

export default UserEditForm;
