import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../common/Button";

const HeaderStyled = styled.div`
  background-color: var(--color-primary);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DescriptionStyled = styled.div`
  margin-top: 20px;
  max-width: 600px;
`;

const MainHeader = () => {
  const { t, ready } = useTranslation("main");
  const navigate = useNavigate();
  const notReady = !ready;

  if (notReady) {
    return <></>;
  }
  return (
    <HeaderStyled className="text-center items-center">
      <h2>{t("title")}</h2>
      <DescriptionStyled className="px-3">{t("desc")}</DescriptionStyled>
      <Button
        className="my-4"
        onClick={() => {
          navigate("login");
        }}
      >
        {t("login")}
      </Button>
    </HeaderStyled>
  );
};

export default MainHeader;
