import { getFileUrl } from "../../../api/utils";
import DicomDetail from "./DicomDetail";
import PhotoDetail from "./PhotoDetail";

interface Props {
  url?: string;
}

const FileDetail = ({ url }: Props) => {
  if (!url) {
    return <p>No url</p>;
  }
  const fullUrl = getFileUrl(url);
  const isDicom = url.endsWith(".dcm");

  return isDicom ? (
    <DicomDetail url={fullUrl} />
  ) : (
    <PhotoDetail url={fullUrl} />
  );
};

export default FileDetail;
