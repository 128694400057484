import { useTranslation } from "react-i18next";
import Main from "../common/Main";

const NotFoundPage = () => {
  const { t } = useTranslation(["translation"]);
  return (
    <Main>
      <div className="w-full mt-10 flex justify-center text-center">
        <div>
          <h3>{t("page-not-found.title")}</h3>
          <p className="mt-3">{t("page-not-found.description")}</p>
        </div>
      </div>
    </Main>
  );
};

export default NotFoundPage;
