import styled from "styled-components";

const Icon = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
  object-fit: scale-down;
`;

export default Icon;
