import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { usersApi } from "../../api";
import { CodeDTO } from "../../api/data-contracts";
import { getMUNIRedirectUrl } from "../../api/utils";
import { useAuth } from "../../auth/AuthContext";
import Main from "../common/Main";
import TitleDiv from "../common/TitleDiv";

const MUNIButton = styled.button`
  max-width: 300px;
`;

const LoginPage = () => {
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { t, ready, i18n } = useTranslation("translation");
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const from = localStorage.getItem("fromUrl");
  console.log("first from " + from);

  const afterLogin = () => {
    console.log("second from " + from);
    if (from != null) {
      localStorage.removeItem("fromUrl");
      navigate(from!, { replace: true });
      console.log("moving to from " + from);
    } else {
      console.log("moving to /records");
      navigate("/records");
    }
  };

  if (isAuthenticated) {
    afterLogin();
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      fetchAccessToken(code);
    }
  }, []);

  const fetchAccessToken = async (code: string) => {
    setLoading(true);
    setErrorMsg(undefined);
    try {
      const codeDTO: CodeDTO = { code: code };
      const response = await usersApi.handleCallback(codeDTO);
      const token = response.data.code!;
      const user = await login(token);

      if (user) {
        afterLogin();
      }
    } catch (error) {
      setErrorMsg(t("login-error-not")!);
      console.error("Error during token fetch and login:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!ready) {
    return <></>;
  }

  const getMuniImgName = () => {
    if (i18n.language === "en") {
      return "light_en.png";
    }
    return "light_cs.png";
  };

  const handleRedirect = () => {
    window.location.href = getMUNIRedirectUrl();
  };

  const muniButton = (
    <div className="flex justify-center text-center flex-col items-center">
      {errorMsg !== undefined ? (
        <p className="text-red-500 mb-10">{errorMsg}</p>
      ) : (
        ""
      )}
      <MUNIButton onClick={handleRedirect}>
        {<img src={"/images/muni_login/" + getMuniImgName()} alt="" />}
      </MUNIButton>
    </div>
  );

  return (
    <Main>
      <TitleDiv title={t("login-title")} />
      <div className="mt-10">{loading ? "Loading..." : muniButton}</div>
    </Main>
  );
};

export default LoginPage;
