import { useTranslation } from "react-i18next";
import { RoleTypeEnum } from "../../../api/ExtraEnums";
import { useAuth } from "../../../auth/AuthContext";
import Main from "../../common/Main";
import TitleDiv, { EditButtonProps } from "../../common/TitleDiv";
import RecordsSection from "./components/RecordsSection";

const RecordsPage = () => {
  const { userInfo } = useAuth();
  const { t, ready } = useTranslation("records", { keyPrefix: "list" });
  const notReady = !ready;

  if (notReady) {
    return <></>;
  }

  const buttonProps: EditButtonProps = {
    title: t("button"),
    route: "/record/create",
  };

  const role = userInfo?.user?.role;

  return (
    <Main>
      <TitleDiv
        title={t("title")}
        button={
          role === RoleTypeEnum.ADMIN || role === RoleTypeEnum.TEACHER
            ? buttonProps
            : undefined
        }
      />
      <RecordsSection />
    </Main>
  );
};

export default RecordsPage;
