import styled from "styled-components";

const TitleCol = styled.td`
  padding-right: 15px;
  width: 200px;
  padding: 9px 4px !important;
`;

interface DataRowProps {
  title: string;
  value?: string | number;
}
const DataRow = ({ title, value }: DataRowProps) => {
  return (
    <tr>
      <TitleCol className="bold">{title}:</TitleCol>
      <td>{value}</td>
    </tr>
  );
};

export default DataRow;
