import Main from "../common/Main";
import InfoSection from "./components/InfoSection";
import MainHeader from "./components/MainHeader";

const MainPage = () => {
  return (
    <Main>
      <MainHeader />
      <InfoSection />
    </Main>
  );
};

export default MainPage;
