/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from "./http-client";

export class AuthApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags AuthApi
   * @name Login
   * @request POST:/api/auth/redirect-to-provider
   */
  login = (params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/api/auth/redirect-to-provider`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthApi
   * @name HandleCallback
   * @request POST:/api/auth/handle-provider-callback
   */
  handleCallback = (params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/api/auth/handle-provider-callback`,
      method: "POST",
      ...params,
    });
}
