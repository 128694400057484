import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { axiosClient, usersApi } from "../api";
import { UserInfoDTO } from "../api/data-contracts";

type AuthState = {
  isAuthenticated: boolean;
  token: string | null;
  userInfo: UserInfoDTO | null;
};

type AuthAction =
  | { type: "LOGIN"; payload: { token: string; userInfo: UserInfoDTO } }
  | { type: "LOGOUT" };

const initialState: AuthState = {
  isAuthenticated: false,
  token: null,
  userInfo: null,
};

const AuthContext = createContext<
  AuthState & {
    login: (token: string) => Promise<UserInfoDTO | null>;
    logout: () => void;
  }
>(initialState as any);

const AuthReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("token", action.payload.token);
      axiosClient.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${action.payload.token}`;
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        userInfo: action.payload.userInfo,
      };
    case "LOGOUT":
      localStorage.removeItem("token");
      delete axiosClient.defaults.headers.common["Authorization"];
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        userInfo: null,
      };
    default:
      return state;
  }
};

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const login = async (token: string) => {
    axiosClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    try {
      const userData = await usersApi
        .getCurrentUser()
        .then((userData) => userData.data);

      dispatch({
        type: "LOGIN",
        payload: { token, userInfo: userData },
      });
      return userData;
    } catch {
      logout();
      return null;
    }
  };

  const logout = () => {
    dispatch({ type: "LOGOUT" });
  };

  const performAutomaticLogin = async (savedToken: string) => {
    console.log("starting automatic login");
    await login(savedToken);
  };

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    if (jwtToken) {
      performAutomaticLogin(jwtToken);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
