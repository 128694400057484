import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { sortingFunc } from "../../../utils/Converter";
import { DeepOmit, UnDot } from "../../../utils/DeepOmit";
import Button from "../../common/Button";
import { Field, Form } from "../../common/form";
import FormDiv from "../../common/form/FormDiv";
import { MedicalDataDTO, RecordCreateDTO } from "../../../api/data-contracts";
import { fileApi, recordApi } from "../../../api";

type Props = {
  diagnosis: MedicalDataDTO[];
  disciplines: MedicalDataDTO[];
  genders: MedicalDataDTO[];
  locations: MedicalDataDTO[];
  surgeries: MedicalDataDTO[];
  submitTitle: string;
};

export type RecordFormCreate = DeepOmit<RecordCreateDTO, UnDot<"photo">> & {
  photo: FileList;
};

const RecordCreateForm = ({
  diagnosis,
  disciplines,
  genders,
  locations,
  surgeries,
}: Props) => {
  const { t, ready, i18n } = useTranslation(["records"]);
  const isLoadingTranslation = !ready;

  const methods = useForm<RecordFormCreate>();
  const { handleSubmit, watch, setValue } = methods;
  const allFields = watch();

  const saveFormData = (data: RecordCreateDTO) => {
    localStorage.setItem("formData", JSON.stringify(data));
  };

  useEffect(() => {
    if (JSON.stringify(allFields).length !== 2) {
      if (
        diagnosis === undefined ||
        disciplines === undefined ||
        genders === undefined ||
        locations === undefined ||
        surgeries === undefined
      ) {
        return;
      }
      saveFormData(allFields);
    }
  }, [allFields]);

  useEffect(() => {
    const savedFormData = localStorage.getItem("formData");
    if (savedFormData) {
      const cachedData = JSON.parse(savedFormData);
      setValue("titleCS", cachedData.titleCS);
      setValue("titleEN", cachedData.titleEN);
      setValue("surgeryId", cachedData.surgeryId);
      setValue("age", cachedData.age);
      setValue("disciplineId", cachedData.disciplineId);
      setValue("genderId", cachedData.genderId);
      setValue("locationId", cachedData.locationId);
      setValue("descriptionCS", cachedData.descriptionCS);
      setValue("descriptionEN", cachedData.descriptionEN);
      setValue("diagnosisId", cachedData.diagnosisId);
    }
  }, [setValue, disciplines, genders, diagnosis, locations, surgeries]);

  useEffect(() => {
    const currentDisciplineId = methods.getValues()["disciplineId"];
    if (currentDisciplineId === "" || currentDisciplineId === undefined) {
      const ortopedy = disciplines.filter(
        (d) => d.nameEN === "Surgery, Traumatology, Orthopaedic Surgery"
      );
      if (ortopedy.length > 0) {
        setValue("disciplineId", ortopedy[0].id);
      }
    }
  }, [disciplines, methods, setValue]);

  let { mutate: onSubmit } = useMutation({
    mutationKey: ["recordCreate"],
    mutationFn: async (data: RecordFormCreate) => {
      const formValues = methods.getValues();
      let photo = formValues.photo.item(0)!;
      const paths: string[] = await fileApi
        .uploadFile({ file: photo })
        .then((resp) => resp.data["paths"]);

      data["photoId"] = paths[0];
      return recordApi.createRecord(data);
    },
    onSuccess: () => {
      navigate(`/records`);
    },
  });

  const navigate = useNavigate();

  if (isLoadingTranslation) {
    return (
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit((d) => onSubmit(d))} t={t}>
          <Field.Text isLoading />
          <Field.Number isLoading />
          <FormDiv>
            <Field.Number isLoading />
            <Field.Dropdown isLoading />
            <Field.Dropdown isLoading />
            <Field.Dropdown isLoading />
          </FormDiv>
          <Field.TextArea isLoading />

          <Button>
            <Skeleton width="8ch" />
          </Button>
        </Form>
      </FormProvider>
    );
  } else {
    return (
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit((d) => onSubmit(d))} t={t}>
          <Field.Text<RecordFormCreate>
            field="titleCS"
            localeKey="headers.titleCS"
          />
          <Field.Text<RecordFormCreate>
            field="titleEN"
            localeKey="headers.titleEN"
          />
          <Field.Number<RecordFormCreate>
            field="age"
            localeKey="headers.age"
            min={0}
            max={120}
          />
          <Field.RadioField<RecordFormCreate>
            field="genderId"
            localeKey="gender"
            language={i18n.language}
            options={sortingFunc(genders, i18n.language)}
          />
          <Field.Dropdown<RecordFormCreate>
            field="diagnosisId"
            localeKey="diagnosis"
            language={i18n.language}
            options={sortingFunc(diagnosis, i18n.language)}
          />
          <Field.Dropdown<RecordFormCreate>
            field="surgeryId"
            localeKey="surgery"
            language={i18n.language}
            options={sortingFunc(surgeries, i18n.language)}
          />

          <Field.Dropdown<RecordFormCreate>
            field="locationId"
            localeKey="location"
            language={i18n.language}
            options={sortingFunc(locations, i18n.language)}
          />
          <Field.Dropdown<RecordFormCreate>
            field="disciplineId"
            localeKey="discipline"
            language={i18n.language}
            options={sortingFunc(disciplines, i18n.language)}
          />
          <Field.Text<RecordFormCreate>
            field="descriptionCS"
            localeKey="headers.descriptionCS"
          />
          <Field.Text<RecordFormCreate>
            field="descriptionEN"
            localeKey="headers.descriptionEN"
          />
          <Field.File<RecordFormCreate>
            field="photo"
            localeKey="headers.photo"
            required={true}
          />
          <Button type="submit">{t("create.button")}</Button>
        </Form>
      </FormProvider>
    );
  }
};

export default RecordCreateForm;
