import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { medicalDataApi } from "../../../api";
import { MedicalDataUpdateDTO } from "../../../api/data-contracts";
import Button from "../../common/Button";
import { Field, Form } from "../../common/form";
import FormDiv from "../../common/form/FormDiv";
import EditingTableInfo from "./EditingTableInfo";
import { TableType } from "../../../api/ExtraEnums";

type Props = {
  id: string;
  tableType: TableType;
  onSubmit: (data: MedicalDataUpdateDTO) => void;
};

const MedicalDataEditForm = ({ onSubmit, tableType, id }: Props) => {
  const { t, ready } = useTranslation(["records"]);
  const isLoadingTranslation = !ready;

  const methods = useForm<MedicalDataUpdateDTO>();
  const { handleSubmit, setValue } = methods;

  const loadMedicalData = useCallback(async () => {
    await medicalDataApi.findMedicalData(tableType, id).then((resp) => {
      const data = resp.data;
      setValue("nameCS", data.nameCS!);
      setValue("nameEN", data.nameEN!);
      return data;
    });
  }, [id, setValue, tableType]);

  useEffect(() => {
    loadMedicalData();
  }, [loadMedicalData]);

  if (isLoadingTranslation) {
    return (
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} t={t}>
          <Field.Text isLoading />
          <Field.Number isLoading />
          <FormDiv>
            <Field.Number isLoading />
            <Field.Dropdown isLoading />
            <Field.Dropdown isLoading />
            <Field.Dropdown isLoading />
          </FormDiv>
          <Field.TextArea isLoading />

          <Button>
            <Skeleton width="8ch" />
          </Button>
        </Form>
      </FormProvider>
    );
  } else {
    return (
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} t={t}>
          <EditingTableInfo t={t} tableType={tableType!} />
          <Field.Text<MedicalDataUpdateDTO>
            field="nameCS"
            localeKey="headers.nameCS"
          />
          <Field.Text<MedicalDataUpdateDTO>
            field="nameEN"
            localeKey="headers.nameEN"
          />
          <Button className="mt-8" type="submit">
            {t("edit.button")}
          </Button>
        </Form>
      </FormProvider>
    );
  }
};

export default MedicalDataEditForm;
