import { axiosClient } from "./index";

export function getFileUrl(id: string) {
  return new URL(
    `api/file/get-file/` + id,
    axiosClient.defaults.baseURL
  ).toString();
}

export function getMUNIRedirectUrl() {
  return new URL(
    "/api/user/redirect-to-provider",
    axiosClient.defaults.baseURL
  ).toString();
}
