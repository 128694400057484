import { error } from "console";
import { TFunction } from "i18next";
import { createContext, forwardRef, HTMLProps } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledInput = styled.input`
  height: 40px;
  width: 100%;
  border-color: var(--color-grey);
  border-width: 1px;
  border-radius: 5px;
  padding-left: 8px;

  &:placeholder {
    color: red;
    opacity: 1; /* Firefox */
  }
`;

const StyledArea = styled.textarea`
  height: calc(40px * 5);
  width: 100%;
  border-color: var(--color-grey);
  border-width: 1px;
  border-radius: 5px;
  padding-left: 8px;

  &:placeholder {
    color: red;
    opacity: 1; /* Firefox */
  }
`;

export const FormContext = createContext<{
  t: TFunction<string, any, string>;
}>({
  t: ((key: string | string[], options?: any): string => "") as TFunction<
    string,
    any,
    string
  >,
});

type FormProps = HTMLProps<HTMLFormElement> & {
  t: TFunction<string, any, string>;
};
type GroupProps = HTMLProps<HTMLDivElement>;
type LabelProps = HTMLProps<HTMLLabelElement>;
type ControlProps = HTMLProps<HTMLInputElement>;
type ErrorProps = { children?: any };

const Form = ({ t, children, ...props }: FormProps) => {
  return (
    <FormContext.Provider value={{ t }}>
      <form {...props}>{children}</form>
    </FormContext.Provider>
  );
};

const Group = ({ children, className, ...props }: GroupProps) => {
  return (
    <div className={"mt-3 flex-1 " + className ?? ""} {...props}>
      {children}
    </div>
  );
};

const Label = ({ children, ...props }: LabelProps) => {
  return <label {...props}>{children}</label>;
};

const Control = forwardRef<HTMLInputElement, ControlProps>(
  ({ children, className, ...props }, ref) => {
    // @ts-ignore
    return <StyledInput ref={ref} {...props} />;
  }
);

const Area = forwardRef<HTMLInputElement, ControlProps>(
  ({ children, className, ...props }, ref) => {
    // @ts-ignore
    return <StyledArea ref={ref} {...props} />;
  }
);

const Error = ({ children, ...props }: ErrorProps) => {
  const { t } = useTranslation("translation");
  return (
    <div className="error text-red-500 mt-1 font-bold" {...props}>
      {children && t(children.message)}
    </div>
  );
};

Form.Group = Group;
Form.Label = Label;
Form.Control = Control;
Form.Area = Area;
Form.Error = Error;

export default Form;
