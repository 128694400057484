import { useTranslation } from "react-i18next";
import { TableDiv, TableHeader } from "../../../common/table/Table";
import UserRow from "./UserRow";
import { UserDTO } from "../../../../api/data-contracts";

interface UsersTableProps {
  users: UserDTO[];
}

const UsersTable = ({ users }: UsersTableProps) => {
  const { t, ready } = useTranslation("users", { keyPrefix: "headers" });

  const notReady = !ready;

  if (notReady) {
    return <></>;
  }

  return (
    <TableDiv>
      <TableHeader>
        <tr>
          <th>{t("name")}</th>
          <th>{t("uco")}</th>
          <th>{t("role")}</th>
          <th>{t("date-added")}</th>
          <th>{t("edit")}</th>
        </tr>
      </TableHeader>
      <tbody>
        {users.map((user, i) => (
          <UserRow key={i} rowNum={i + 1} user={user} />
        ))}
      </tbody>
    </TableDiv>
  );
};

export default UsersTable;
