/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, HttpClient, RequestParams } from "./http-client";

export class FileApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags FileApi
   * @name UploadFile
   * @request POST:/api/file/upload-file
   */
  uploadFile = (
    data: {
      /** @format dcm,png,jpeg,jpg */
      file?: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<any, void>({
      path: `/api/file/upload-file`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags FileApi
   * @name GetFile
   * @request GET:/api/file/get-file/{fileName}
   */
  getFile = (fileName: string, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/api/file/get-file/${fileName}`,
      method: "GET",
      ...params,
    });
}
