import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext";
import LanguageSwitch from "./LanguageSwitch";
import NavMenu from "./NavMenu";
import { UserInfoDTO } from "../../api/data-contracts";
import { RoleTypeEnum } from "../../api/ExtraEnums";

const LanguageBox = () => {
  return (
    <div className="flex justify-center">
      <LanguageSwitch />
    </div>
  );
};

function UnauthenticatedNavbar() {
  const { t, ready } = useTranslation("nav");
  const notReady = !ready;

  if (notReady) {
    return <></>;
  } else {
    return (
      <NavMenu
        left={<></>}
        right={
          <>
            <NavLink to="/">{t("main")}</NavLink>
            <NavLink to="/login">{t("login")}</NavLink>
            <LanguageBox />
          </>
        }
      />
    );
  }
}

function AuthenticatedNavbar({userInfo}: {userInfo: UserInfoDTO}) {
  const { t, ready: localeReady } = useTranslation("nav");
  const isLoading = !localeReady;

  if (isLoading) {
    return <p>Loading</p>;
  } else {
    const currentRole = userInfo.user!.role;
    return (
      <NavMenu
        left={<></>}
        right={
          <>
            <NavLink to="/records">{t("records")}</NavLink>
            {currentRole === RoleTypeEnum.ADMIN && <NavLink to="/users">{t("users")}</NavLink>}
            {currentRole === RoleTypeEnum.ADMIN && <NavLink to="/tables">{t("tables")}</NavLink>}
            <NavLink to="/profile">{t("profile")}</NavLink>
            <LanguageBox />
          </>
        }
      />
    );
  }
}

const Navbar = () => {
  const {isAuthenticated, userInfo} = useAuth();

  if (isAuthenticated && userInfo) {
    return <AuthenticatedNavbar userInfo={userInfo} />;
  } else {
    return <UnauthenticatedNavbar />;
  }
};

export default Navbar;
