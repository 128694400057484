interface DropdownProps {
  options: string[];
  translations: string[];
  initValue: string;
  title: string;
  onChange: (newValue: string) => void;
}
const Dropdown = ({
  options,
  initValue,
  translations,
  title,
  onChange,
}: DropdownProps) => {
  const handleDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    onChange(event.target.value);
  };

  return (
    <div className="mt-3">
      <p className="mt-4 mb-2 bold">{title}</p>
      <select defaultValue={initValue} onChange={handleDropdownChange}>
        {options.map((o, index) => (
          <option key={index} value={o}>
            {translations.at(index)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
