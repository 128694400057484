import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { RecordDTO } from "../../../api/data-contracts";
import Main from "../../common/Main";
import TitleDiv, { EditButtonProps } from "../../common/TitleDiv";
import RecordData from "./components/RecordData";

import styled from "styled-components";
import { recordApi } from "../../../api";
import FileDetail from "../photo/FileDetail";
import MedicalData from "./components/MedicalData";
import { RoleTypeEnum } from "../../../api/ExtraEnums";
import { useAuth } from "../../../auth/AuthContext";

const PhotoDiv = styled.div`
  max-height: 500px;
`;

const RecordDetailPage = () => {
  const { id } = useParams();
  const { userInfo } = useAuth();
  const { t, ready } = useTranslation("records", { keyPrefix: "detail" });
  const notReady = !ready;

  const { isLoading, isError, error, data } = useQuery<RecordDTO, AxiosError>({
    queryKey: ["record", id],
    queryFn: () => recordApi.findRecordById(id!).then((resp) => resp.data),
  });

  if (notReady) {
    return <></>;
  }

  if (id === undefined) {
    return <p>Error: no id</p>;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (isError) {
    return <p>{error.message}</p>;
  }
  const buttonProps: EditButtonProps = {
    title: t("button"),
    route: "/record/edit/" + id,
  };

  const role = userInfo?.user?.role;

  return (
    <Main>
      <TitleDiv
        title={t("title")}
        hasBackButton={true}
        hasCopyButton={true}
        button={
          data &&
          (role === RoleTypeEnum.ADMIN ||
            (role === RoleTypeEnum.TEACHER &&
              userInfo?.user?.id === data.user?.id))
            ? buttonProps
            : undefined
        }
      />
      <div className="mt-10 flex flex-col lg:flex-row gap-5">
        <div className="w-full lg:w-1/2 flex flex-col gap-12">
          <MedicalData record={data!} />
          <RecordData record={data!} />
        </div>
        <PhotoDiv className="max-w-sm w-2/3 mt-10 lg:max-w-full lg:mt-0 px-10">
          <FileDetail url={data?.photoId} />
        </PhotoDiv>
      </div>
    </Main>
  );
};
export default RecordDetailPage;
