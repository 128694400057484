import { HTMLProps, ReactNode } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Main = (props: HTMLProps<HTMLDivElement> & { children: ReactNode }) => {
  return (
    <main>
      <Navbar />
      {props.children}
      <Footer />
    </main>
  );
};

export default Main;
