import { HTMLProps } from "react";

const FormDiv = (props: HTMLProps<HTMLDivElement>) => {
  return (
    <div className={"flex flex-wrap gap-4 " + props.className ?? ""}>
      {props.children}
    </div>
  );
};

export default FormDiv;
