import { useTranslation } from "react-i18next";
import { RecordDTO } from "../../../../api/data-contracts";
import DataRow from "./DataRow";

interface RecordDataProps {
  record: RecordDTO;
}
const RecordData = ({ record }: RecordDataProps) => {
  const { t, ready } = useTranslation("records");
  const notReady = !ready;

  if (notReady) {
    return <></>;
  }

  return (
    <div className="ml-10">
      <h4>{t("detail.record-data")}</h4>
      <DataRow
        title={t("headers.creator")}
        value={record.user?.firstName + " " + record.user?.secondName}
      />
      <DataRow title={t("headers.creation-date")} value={record.creationDate} />
    </div>
  );
};

export default RecordData;
