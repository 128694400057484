import { useMutation } from "@tanstack/react-query";

import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { usersApi } from "../../../api";
import { UserUpdateDTO } from "../../../api/data-contracts";
import Main from "../../common/Main";
import UserEditForm from "./UserEditForm";
import Icon from "../../common/Icon";
import { useState } from "react";

const UserEditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t, ready: localeReady } = useTranslation(["users"]);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  let { mutate: onSubmit, isLoading: isLoadingSubmit } = useMutation({
    mutationKey: ["user", id],
    mutationFn: (data: UserUpdateDTO) => {
      data.id = id;
      return usersApi.updateUser(data);
    },
    onSuccess: () => {
      navigate(`/users`);
    },
  });

  const deleteUser = async () => {
    try {
      await usersApi.removeUserById(id!);
      navigate("/users");
    } catch (error) {
      setErrorMsg(t("user-delete-error")!);
      console.error("Error during deleting user:", error);
    }
  };

  if (id === undefined) {
    return <p>Error: no id</p>;
  }

  const isLoading = isLoadingSubmit || !localeReady;

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Main>
      <div className="flex items-center max-w-screen-xl">
        <div className="p-10">
          <div className="flex justify-between mb-8">
            <h3>{isLoading ? <Skeleton width="8ch" /> : t("edit.title")}</h3>
            <Icon
              className="mt-2 ml-10"
              src="/images/icons/trash.png"
              alt="trash icon"
              onClick={() => deleteUser()}
            />
          </div>

          {errorMsg !== undefined ? (
            <p className="text-red-500 mb-10">{errorMsg}</p>
          ) : (
            ""
          )}
          <UserEditForm id={id} onSubmit={onSubmit} />
        </div>
      </div>
    </Main>
  );
};

export default UserEditPage;
