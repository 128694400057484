import { TFunction } from "i18next";

interface Props {
  t: TFunction;
  tableType: string;
}

const EditingTableInfo = ({ t, tableType }: Props) => {
  const editingTableTitle = t(`edit.editing-table`);
  const typeTrans = t(`table-type-enum.${tableType}`);
  return (
    <p className="mb-8">
      {editingTableTitle + ": "}
      <span className="bold">{typeTrans}</span>
    </p>
  );
};

export default EditingTableInfo;
