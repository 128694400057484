import { useTranslation } from "react-i18next";
import styled from "styled-components";

const TopLine = styled.div`
  background-color: var(--color-grey-light);
  width: 100%;
  height: 1px;
  margin-bottom: 15px;
`;

const Footer = () => {
  const { t, ready } = useTranslation("translation");
  const notReady = !ready;
  if (notReady) {
    return <></>;
  }
  return (
    <div className="text-center my-10 px-3 md:px-10">
      <TopLine />
      <p>{t("footer-platform")}</p>
      <p>
        <a
          href="https://www.med.muni.cz/o-fakulte/organizacni-struktura/119614-oddeleni-pro-data-a-technologie"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("footer-creator")}
        </a>
      </p>
    </div>
  );
};

export default Footer;
