import { useEffect, useMemo } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

type Props = {
  redirectTo: string;
};

function RequiresAuth({ redirectTo }: Props) {
  const { isAuthenticated } = useAuth();

  const location = useLocation();

  const currentLocation = useMemo(
    () => location.pathname + location.search,
    [location]
  );

  useEffect(() => {
    localStorage.setItem("fromUrl", currentLocation);
  }, [currentLocation]);

  const isLocalhost = window.location.hostname === "localhost";

  if (isLocalhost || isAuthenticated) {
    return <Outlet />;
  }

  console.log("currentLocation " + currentLocation);

  return <Navigate to={redirectTo} replace state={{ from: currentLocation }} />;
}

export default RequiresAuth;
