import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { fileApi, recordApi } from "../../../api";

import { useMutation } from "@tanstack/react-query";
import {
  MedicalDataDTO,
  RecordDTO,
  RecordUpdateDTO,
} from "../../../api/data-contracts";
import { sortingFunc } from "../../../utils/Converter";
import { DeepOmit, UnDot } from "../../../utils/DeepOmit";
import Button from "../../common/Button";
import { Field, Form } from "../../common/form";
import FormDiv from "../../common/form/FormDiv";
import FileDetail from "../photo/FileDetail";

export type RecordFormUpdate = DeepOmit<RecordUpdateDTO, UnDot<"photo">> & {
  photo?: FileList;
};

type Props = {
  id: string;
  diagnosis: MedicalDataDTO[];
  disciplines: MedicalDataDTO[];
  genders: MedicalDataDTO[];
  locations: MedicalDataDTO[];
  surgeries: MedicalDataDTO[];
};

const RecordEditForm = ({
  id,
  diagnosis,
  disciplines,
  genders,
  locations,
  surgeries,
}: Props) => {
  const { t, ready, i18n } = useTranslation(["records"]);
  const isLoadingTranslation = !ready;

  const methods = useForm<RecordFormUpdate>();
  const { handleSubmit, setValue } = methods;

  const [record, setRecord] = useState<RecordDTO | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    recordApi.findRecordById(id).then((resp) => {
      const d = resp.data;
      if (d === undefined) {
        return;
      }
      setValue("id", d.id);
      setValue("titleCS", d.titleCS);
      setValue("titleEN", d.titleEN);
      setValue("surgeryId", d.surgery?.id);
      setValue("age", d.age);
      setValue("disciplineId", d.discipline?.id);
      setValue("genderId", d.gender?.id);
      setValue("locationId", d.location?.id);
      setValue("descriptionCS", d.descriptionCS);
      setValue("descriptionEN", d.descriptionEN);
      setValue("diagnosisId", d.diagnosis?.id);
      setValue("photoId", d.photoId);
      setRecord(resp.data);
      return resp.data;
    });
  }, [id, setValue, setRecord]);

  let { mutate: onSubmit } = useMutation({
    mutationKey: ["record", id],
    mutationFn: async (data: RecordFormUpdate) => {
      const formValues = methods.getValues();
      if (formValues.photo != null && formValues.photo?.length !== 0) {
        let photo = formValues.photo.item(0)!;
        const paths: string[] = await fileApi
          .uploadFile({ file: photo })
          .then((resp) => resp.data["paths"]);

        data["photoId"] = paths[0];
      }

      return recordApi.updateRecord(data);
    },
    onSuccess: () => {
      navigate(`/records`);
    },
  });

  if (isLoadingTranslation) {
    return (
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit((d) => onSubmit(d))} t={t}>
          <Field.Text isLoading />
          <Field.Number isLoading />
          <FormDiv>
            <Field.Number isLoading />
            <Field.Dropdown isLoading />
            <Field.Dropdown isLoading />
            <Field.Dropdown isLoading />
          </FormDiv>
          <Field.TextArea isLoading />

          <Button>
            <Skeleton width="8ch" />
          </Button>
        </Form>
      </FormProvider>
    );
  } else {
    return (
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit((d) => onSubmit(d))} t={t}>
          <Field.Text<RecordFormUpdate>
            field="titleCS"
            localeKey="headers.titleCS"
          />
          <Field.Text<RecordFormUpdate>
            field="titleEN"
            localeKey="headers.titleEN"
          />
          <Field.Number<RecordFormUpdate>
            field="age"
            localeKey="headers.age"
            min={0}
            max={120}
          />
          <Field.RadioField<RecordFormUpdate>
            field="genderId"
            localeKey="gender"
            language={i18n.language}
            options={sortingFunc(genders, i18n.language)}
          />
          <Field.Dropdown<RecordFormUpdate>
            field="diagnosisId"
            localeKey="diagnosis"
            language={i18n.language}
            options={sortingFunc(diagnosis, i18n.language)}
          />
          <Field.Dropdown<RecordFormUpdate>
            field="surgeryId"
            localeKey="surgery"
            language={i18n.language}
            options={sortingFunc(surgeries, i18n.language)}
          />
          <Field.Dropdown<RecordFormUpdate>
            field="locationId"
            localeKey="location"
            language={i18n.language}
            options={sortingFunc(locations, i18n.language)}
          />
          <Field.Dropdown<RecordFormUpdate>
            field="disciplineId"
            localeKey="discipline"
            language={i18n.language}
            options={sortingFunc(disciplines, i18n.language)}
          />
          <Field.Text<RecordFormUpdate>
            field="descriptionCS"
            localeKey="headers.descriptionCS"
          />
          <Field.Text<RecordFormUpdate>
            field="descriptionEN"
            localeKey="headers.descriptionEN"
          />
          {record?.photoId && (
            <div className="my-4">
              <p className="pb-3">{t("edit.saved-photo")}:</p>
              <FileDetail url={record!.photoId!} />
            </div>
          )}

          <Field.File<RecordFormUpdate>
            field="photo"
            localeKey="edit.new-photo"
            required={false}
          />

          <Button type="submit">{t("edit.button")}</Button>
        </Form>
      </FormProvider>
    );
  }
};

export default RecordEditForm;
