import { TFunction } from "i18next";
import { MedicalDataDTO } from "../../../../api/data-contracts";
import Icon from "../../../common/Icon";
import Link from "../../../common/Link";
import { EditTableRow } from "../../../common/table/EditTable";

interface Props {
  rowNum: number;
  data: MedicalDataDTO;
  t: TFunction;
  onDelete: (data: MedicalDataDTO) => void;
  onEdit: (data: MedicalDataDTO) => void;
}

const MedicalDataRow = ({ data, rowNum, t, onDelete, onEdit }: Props) => {
  return (
    <EditTableRow row={rowNum}>
      <td>{data.nameCS}</td>
      <td>{data.nameEN}</td>
      <td>
        <Link onClick={() => onEdit(data)}>{t("headers.edit-button")}</Link>
      </td>
      <td className="pl-5">
        <Icon src="/images/icons/trash.png" onClick={() => onDelete(data)} />
      </td>
    </EditTableRow>
  );
};

export default MedicalDataRow;
