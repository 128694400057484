import { useTranslation } from "react-i18next";
import DataRow from "./DataRow";
import { RecordDTO } from "../../../../api/data-contracts";
import { translateName } from "../../../../utils/Converter";

interface MedicalDataProps {
  record: RecordDTO;
}
const MedicalData = ({ record }: MedicalDataProps) => {
  const { t, ready, i18n } = useTranslation("records");
  const notReady = !ready;

  if (notReady) {
    return <></>;
  }

  const discipline = translateName(record.discipline!, i18n.language);
  const diagnosis = translateName(record.diagnosis!, i18n.language);
  const surgery = translateName(record.surgery!, i18n.language);
  const gender = translateName(record.gender!, i18n.language);
  const location = translateName(record.location!, i18n.language);

  const language = i18n.language;
  return (
    <div className="ml-10">
      <h4>{t("detail.medical-data")}</h4>
      <table className="mt-4">
        <tbody>
          <DataRow
            title={t("headers.title")}
            value={language === "en" ? record.titleEN : record.titleCS}
          />
          <DataRow title={t("headers.discipline")} value={discipline} />
          <DataRow title={t("headers.diagnosis")} value={diagnosis} />
          <DataRow title={t("headers.surgery")} value={surgery} />
          <DataRow title={t("headers.age")} value={record.age} />
          <DataRow title={t("headers.gender")} value={gender} />
          <DataRow title={t("headers.location")} value={location} />
          <DataRow
            title={t("headers.description")}
            value={
              language === "en" ? record.descriptionEN : record.descriptionCS
            }
          />
        </tbody>
      </table>
    </div>
  );
};

export default MedicalData;
