import { useTranslation } from "react-i18next";
import { UserInfoDTO } from "../../../api/data-contracts";
import DataRow from "../../records/detail/components/DataRow";

interface UserInfoDivProps {
  userInfo: UserInfoDTO;
}
const UserInfoDiv = ({ userInfo }: UserInfoDivProps) => {
  const { t } = useTranslation(["users"]);
  const user = userInfo.user!;

  return (
    <table className="mt-4">
      <tbody>
        <DataRow
          title={t("headers.name")}
          value={`${user.firstName} ${user.secondName}`}
        />
        <DataRow title={t("headers.date-added")} value={`${user.dateAdded}`} />
        <DataRow title={t("headers.uco")} value={`${user.uco}`} />
        <DataRow
          title={t("headers.num-of-records")}
          value={`${userInfo.numOfRecords}`}
        />
      </tbody>
    </table>
  );
};

export default UserInfoDiv;
