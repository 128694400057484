//inspired by react-dicom-viewer

import React, { useEffect, useState } from "react";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import * as dicomParser from "dicom-parser";
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
interface Props {
  /**
   * @description 选择请求类型
   */
  requestType: "wadouri" | "wadors" | "dicomweb" | "dicomfile";

  /**
   * @example 'http://localhost:9000/example.dcm'
   * @description 请求地址URL
   */
  url: string;

  /**
   * @description slope
   */
  slope?: number;

  /**
   * @description intercept
   */
  intercept?: number;

  /**
   * @description windowCenter
   */
  windowCenter?: number;

  /**
   * @description windowWidth
   */
  windowWidth?: number;

  /**
   * @description rows
   */
  rows?: number;

  /**
   * @description columns
   */
  columns?: number;

  /**
   * @description height
   */
  height?: number;

  /**
   * @description width
   */
  width?: number;

  /**
   * @description color
   */
  color?: boolean;

  /**
   * @description 成功回调函数 参数image可获取DICOM图像默认显示信息
   */
  onSuccess?: Function;

  /**
   * @description 失败回调函数
   */
  onFail?: Function;
}
function DicomMyViewer(props: Props) {
  const [imageSlope] = useState(props.slope);
  const [imageIntercept] = useState(props.intercept);
  const [imageWindowCenter] = useState(props.windowCenter);
  const [imageWindowWidth] = useState(props.windowWidth);
  const [imageRows] = useState(props.rows);
  const [imageColumns] = useState(props.columns);
  const [imageHeight] = useState(props.height);
  const [imageWidth] = useState(props.width);
  const [imageColor] = useState(props.color);

  const updateViewportSize = () => {
    const element = document.getElementById("dicomImage");
    if (element && cornerstone.getEnabledElement(element)) {
      cornerstone.resize(element, true);
    }
  };

  useEffect(() => {
    const element = document.getElementById("dicomImage");
    if (element !== null) {
      cornerstone.enable(element);
      const ImageId = `${props.requestType}:${props.url}`;
      console.log(ImageId);
      cornerstone
        .loadImage(ImageId)
        .then((image) => {
          const viewport = cornerstone.getDefaultViewportForImage(
            element,
            image
          );
          cornerstone.displayImage(element, image, viewport);
          if (props.onSuccess) {
            props.onSuccess(image);
          }
        })
        .catch((err) => {
          if (props.onFail) {
            props.onFail();
          }
          console.log(err);
        });
      window.addEventListener("resize", updateViewportSize);
    }

    return () => {
      window.removeEventListener("resize", updateViewportSize);
    };
  }, []);
  return (
    <div
      id={"dicomImage"}
      style={{ height: "100%", width: "100%", backgroundColor: "FFFFF" }}
    ></div>
  );
}

export default DicomMyViewer;
