import styled from "styled-components";

const Button = styled.button`
  display: flex;
  flex-direction: row;

  padding: 16px 20px;

  border: none;
  white-space: nowrap;

  height: 45px;
  cursor: pointer;

  /* Text styling */
  justify-content: center;
  align-items: center;

  background-color: ${(props) =>
    props.disabled
      ? "grey"
      : "var(--color-blue)"}; // Conditionally set the background color
  border-radius: 15px;

  font-family: "Nunito";
  font-weight: 600;
  font-size: 16px;
  color: white;

  &:hover {
    opacity: 0.95;
  }
`;

export default Button;
