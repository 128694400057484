import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  PageInputDTO,
  PageResultDTO,
  UserDTO,
} from "../../../../api/data-contracts";
import UsersTable from "./UsersTable";
import { useState } from "react";
import PageSwitcher from "../../../common/PageSwitcher";
import { usersApi } from "../../../../api";

const UsersSection = () => {
  const defaultPage: PageInputDTO = {
    pageNum: 1,
    entitiesPerPage: 20,
  };
  const [page, setPage] = useState<PageInputDTO>(defaultPage);

  const { isLoading, data } = useQuery<PageResultDTO, AxiosError>({
    queryKey: ["users", page],
    queryFn: () => usersApi.findUsers(page).then((resp) => resp.data),
  });

  if (data === undefined || isLoading) {
    return <p>Loading...</p>;
  }

  const changePage = (newPageNum: number) => {
    const newPage = { ...page, pageNum: newPageNum };
    setPage(newPage);
  };

  return (
    <>
      <UsersTable users={data.entities!} />
      <PageSwitcher
        currentPage={page.pageNum!}
        totalPages={data.totalPages!}
        changePage={changePage}
      />
    </>
  );
};

export default UsersSection;
