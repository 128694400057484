import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserDTO } from "../../../../api/data-contracts";
import Link from "../../../common/Link";
import { TableRow } from "../../../common/table/Table";

interface UserRowProps {
  rowNum: number;
  user: UserDTO;
}

const UserRow = ({ user, rowNum }: UserRowProps) => {
  const navigate = useNavigate();
  const { t, ready } = useTranslation("users");
  const notReady = !ready;

  useEffect(() => {}, [user]);

  if (notReady) {
    return <></>;
  }

  const onEditClick = () => {
    navigate("/user/edit/" + user.id);
  };

  return (
    <TableRow row={rowNum}>
      <td>{user.firstName + " " + user.secondName}</td>
      <td>{user.uco}</td>
      <td>{t(`role-enum.${user.role}`)}</td>
      <td>{user.dateAdded}</td>
      <td>
        <Link onClick={onEditClick}>{t("headers.edit-button")}</Link>
      </td>
    </TableRow>
  );
};

export default UserRow;
