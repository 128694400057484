import { useMemo } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

type Props = {
  redirectTo: string;
  acceptedRoles: string[];
};

function RequiresAuthRole({ redirectTo, acceptedRoles }: Props) {
  const { userInfo } = useAuth();

  const location = useLocation();
  const currentLocation = useMemo(
    () => location.pathname + location.search,
    [location]
  );

  const isLocalhost = window.location.hostname === "localhost";
  if (isLocalhost) {
    return <Outlet />;
  }
  const nav = (
    <Navigate to={redirectTo} replace state={{ from: currentLocation }} />
  );

  if (!userInfo) {
    return nav;
  }

  const currentRole = userInfo.user!.role!;
  if (!acceptedRoles.includes(currentRole)) {
    return nav;
  }

  return <Outlet />;
}

export default RequiresAuthRole;
