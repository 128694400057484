import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { MedicalDataDTO, RecordsFilterDTO } from "../../api/data-contracts";
import { sortingFunc } from "../../utils/Converter";
import { Field, Form } from "../common/form";
import Button from "./Button";
import { medicalDataApi } from "../../api";
import { TableType } from "../../api/ExtraEnums";

const FilterStyled = styled.div`
  max-width: 700px;
`;

export const emptyFilterValues: RecordsFilterDTO = {
  text: "",
  diagnosisId: "",
  language: "EN",
  pageNum: 1,
  entitiesPerPage: 20,
};

interface FilterProps {
  initialValues: RecordsFilterDTO;
  onSubmit: (data: RecordsFilterDTO) => void;
}
const Filter = ({ onSubmit, initialValues }: FilterProps) => {
  const { t, ready, i18n } = useTranslation(["records"]);
  const isLoadingTranslation = !ready;

  const methods = useForm<RecordsFilterDTO>({ values: initialValues });
  const { handleSubmit } = methods;

  const {
    isError: isErrorDiagnosis,
    error: errorDiagnosis,
    data: diagnosis,
  } = useQuery<MedicalDataDTO[], AxiosError>({
    queryKey: ["diagnosis"],
    queryFn: () =>
      medicalDataApi
        .findAllMedicalData({ tableType: TableType.DIAGNOSIS })
        .then((resp) => resp.data),
  });

  const resetFilter = () => {
    onSubmit(emptyFilterValues);
  };

  if (isLoadingTranslation || diagnosis == null) {
    return (
      <FilterStyled>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)} t={t}>
            <Field.Text isLoading />
            <Field.Number isLoading />
          </Form>
          {isErrorDiagnosis ? <p>{errorDiagnosis!.message}</p> : ""}
        </FormProvider>
      </FilterStyled>
    );
  } else {
    return (
      <FilterStyled>
        <FormProvider {...methods}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            t={t}
            className="gap-10 px-3 md:px-10 md:flex"
          >
            <div className="md:flex gap-5">
              <Field.Text<RecordsFilterDTO>
                required={false}
                field="text"
                localeKey="filter.search-enter"
              />
              <Field.Dropdown<RecordsFilterDTO>
                required={false}
                field="diagnosisId"
                localeKey="diagnosis"
                language={i18n.language}
                options={sortingFunc(diagnosis, i18n.language)}
              />
            </div>
            <div className="flex gap-4">
              <Button
                className="mt-3 md:mt-8"
                type="submit"
                disabled={!methods.formState.isDirty}
              >
                {t("filter.search")}
              </Button>

              <Button className="mt-3 md:mt-8" onClick={resetFilter}>
                {t("filter.reset")}
              </Button>
            </div>
          </Form>
        </FormProvider>
      </FilterStyled>
    );
  }
};

export default Filter;
