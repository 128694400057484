/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PageResultDTO, RecordCreateDTO, RecordDTO, RecordUpdateDTO, RecordsFilterDTO } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Record<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Record
   * @name FindWithFilter
   * @request POST:/api/record/
   * @secure
   */
  findWithFilter = (data: RecordsFilterDTO, params: RequestParams = {}) =>
    this.request<PageResultDTO, any>({
      path: `/api/record/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Record
   * @name CreateRecord
   * @request POST:/api/record/create
   */
  createRecord = (data: RecordCreateDTO, params: RequestParams = {}) =>
    this.request<RecordDTO, any>({
      path: `/api/record/create`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Record
   * @name UpdateRecord
   * @request POST:/api/record/update
   */
  updateRecord = (data: RecordUpdateDTO, params: RequestParams = {}) =>
    this.request<RecordDTO, any>({
      path: `/api/record/update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Record
   * @name FindRecordById
   * @request GET:/api/record/findById/{id}
   */
  findRecordById = (id: string, params: RequestParams = {}) =>
    this.request<RecordDTO, any>({
      path: `/api/record/findById/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Record
   * @name RemoveRecordById
   * @request DELETE:/api/record/delete/{id}
   */
  removeRecordById = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/record/delete/${id}`,
      method: "DELETE",
      ...params,
    });
}
