import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import Main from "../../common/Main";
import RecordCreateForm from "./MedicalDataCreateForm";

const MedicalDataCreatePage = () => {
  const { t, ready: localeReady } = useTranslation(["tables"]);

  if (!localeReady) {
    return <p>Loading...</p>;
  }

  return (
    <Main>
      <div className="flex items-center max-w-screen-xl">
        <div className="p-10">
          <h3 className="mb-5">
            {!localeReady ? <Skeleton width="8ch" /> : t("title-create")}
          </h3>
          <RecordCreateForm />
        </div>
      </div>
    </Main>
  );
};

export default MedicalDataCreatePage;
