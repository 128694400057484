import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BackButton from "./BackButton";
import Button from "./Button";
import { useTranslation } from "react-i18next";

const TitleTile = styled.div`
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-primary);
  color: white;
`;

const Tooltip = styled.div<{ visible: string }>`
  position: absolute;
  color: black;
  background-color: #f3f3f3;
  padding: 5px 10px;
  width: 100px;
  border-radius: 5px;
  font-size: 12px;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  visibility: ${(props) => (props.visible === "true" ? "visible" : "hidden")};
  opacity: ${(props) => (props.visible === "true" ? 1 : 0)};
  transition: opacity 0.1s ease-in-out;
`;

export interface EditButtonProps {
  title: string;
  route: string;
}

interface TitleDivProps {
  title: string;
  button?: EditButtonProps;
  hasBackButton?: boolean;
  hasCopyButton?: boolean;
}

const TitleDiv = ({
  title,
  button,
  hasBackButton,
  hasCopyButton = false,
}: TitleDivProps) => {
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);
  const { t, ready } = useTranslation(["records"]);

  const btn = !button ? (
    ""
  ) : (
    <Button
      onClick={() => {
        navigate(button?.route);
      }}
    >
      {button?.title}
    </Button>
  );

  const onCopyClick = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setIsCopied(true);
        // Hide the tooltip after 2 seconds
        setTimeout(() => setIsCopied(false), 1500);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const copyUrlButton = (
    <FontAwesomeIcon
      className="mt-2"
      size="lg"
      icon={faLink}
      style={{ cursor: "pointer" }}
      onClick={onCopyClick}
    />
  );

  return (
    <TitleTile className="py-7 px-3 flex md:px-10 flex-col md:flex-row gap-6">
      <div className="flex gap-6">
        {hasBackButton ? <BackButton color="white" /> : ""}
        <h4>{title}</h4>
        <div style={{ position: "relative" }}>
          {hasCopyButton && copyUrlButton}
          <Tooltip visible={isCopied ? "true" : "false"}>
            {t("detail.link-copied")}
          </Tooltip>
        </div>
      </div>
      {btn}
    </TitleTile>
  );
};

export default TitleDiv;
