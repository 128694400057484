import { ReactNode, useContext } from "react";
import { FieldValues, Path, useFormContext } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { LoadingProps, deepFind } from "../../../utils";
import Form, { FormContext } from "./Form";
import { MedicalDataDTO } from "../../../api/data-contracts";
import { translateName } from "../../../utils/Converter";
import { RoleTypeEnum } from "../../../api/ExtraEnums";
import { useTranslation } from "react-i18next";

type DefaultProps<T> = {
  field: Path<T>;
  localeKey: string;
  required?: boolean;
};
type FieldProps<T> = LoadingProps<DefaultProps<T> & { input: ReactNode }>;
type DropdownProps<T> = DefaultProps<T> & {
  options: { name: string; id: string } | any;
  defaultValue?: string;
};

function Field<T extends FieldValues>({
  isLoading,
  field,
  localeKey,
  input,
}: FieldProps<T>) {
  const {
    formState: { errors },
  } = useFormContext<T>();
  const { t } = useContext(FormContext);

  if (isLoading) {
    return (
      <Form.Group className="mb-3">
        <Form.Label>{<Skeleton width="10ch" />}</Form.Label>
        <Skeleton width="100%" height="40px" />
      </Form.Group>
    );
  } else {
    return (
      <Form.Group className="mb-3">
        <Form.Label htmlFor={field}>{t(localeKey)}</Form.Label>
        {input}
        <Form.Error>{deepFind(errors, field)}</Form.Error>
      </Form.Group>
    );
  }
}

function TextField<T extends FieldValues>({
  isLoading,
  field,
  localeKey,
  required = true,
}: LoadingProps<DefaultProps<T>>) {
  const { register } = useFormContext<T>();

  if (isLoading) {
    return <Field isLoading />;
  }

  return (
    <Field
      field={field}
      localeKey={localeKey}
      input={
        <Form.Control
          type="text"
          id={field}
          autoComplete={field}
          className="form-control form-control-lg rounded-0"
          {...register(field, {
            required: { value: required, message: "required-field-error" },
          })}
        />
      }
    />
  );
}

function DateField<T extends FieldValues>({
  isLoading,
  field,
  localeKey,
  required = true,
}: LoadingProps<DefaultProps<T>>) {
  const { register } = useFormContext<T>();

  if (isLoading) {
    return <Field isLoading />;
  }

  return (
    <Field
      field={field}
      localeKey={localeKey}
      input={
        <Form.Control
          type="date"
          id={field}
          autoComplete={field}
          className="form-control form-control-lg rounded-0"
          {...register(field, {
            required: { value: required, message: "This field is required" },
            valueAsDate: true,
          })}
        />
      }
    />
  );
}

function DropdownField<T extends FieldValues>({
  isLoading,
  field,
  localeKey,
  options,
  defaultValue,
  language,
  required = true,
}: LoadingProps<DropdownProps<T>> & { language?: string }) {
  const { register } = useFormContext<T>();
  const { t, ready } = useTranslation(["users"]);

  if (isLoading || !ready) {
    return <Field isLoading />;
  }

  return (
    <Field
      field={field}
      localeKey={"headers." + localeKey}
      input={
        <select
          defaultValue={defaultValue}
          className="w-full"
          {...register(field, {
            required: { value: required, message: "required-field-error" },
          })}
        >
          <option value="">---</option>
          {language &&
            options.map((option: MedicalDataDTO) => (
              <option key={option.id} value={option.id}>
                {translateName(option, language!)}
              </option>
            ))}
          {!language &&
            options.map((option: RoleTypeEnum) => (
              <option key={option} value={option}>
                {t("role-enum." + option)}
              </option>
            ))}
        </select>
      }
    />
  );
}

function RadioField<T extends FieldValues>({
  isLoading,
  field,
  localeKey,
  options,
  defaultValue,
  language,
  required = true,
}: LoadingProps<DropdownProps<T>> & { language?: string }) {
  const { register } = useFormContext<T>();
  const { t, ready } = useTranslation(["users"]);

  if (isLoading || !ready) {
    return <Field isLoading />;
  }

  return (
    <Field
      field={field}
      localeKey={"headers." + localeKey}
      input={
        <div className="flex flex-col w-full">
          {options.map((option: MedicalDataDTO | RoleTypeEnum) => (
            <label
              key={typeof option === "object" ? option.id : option}
              className="flex items-center"
            >
              <input
                type="radio"
                value={typeof option === "object" ? option.id : option}
                {...register(field, {
                  required: {
                    value: required,
                    message: "required-field-error",
                  },
                })}
                defaultChecked={
                  defaultValue ===
                  (typeof option === "object" ? option.id : option)
                }
              />
              <span className="ml-2">
                {language && typeof option === "object"
                  ? translateName(option, language)
                  : t("role-enum." + option)}
              </span>
            </label>
          ))}
        </div>
      }
    />
  );
}

function EmailField<T extends FieldValues>({
  isLoading,
  field,
  localeKey,
  required = true,
}: LoadingProps<DefaultProps<T>>) {
  const { register } = useFormContext<T>();

  if (isLoading) {
    return <Field isLoading />;
  }

  return (
    <Field
      field={field}
      localeKey={localeKey}
      input={
        <Form.Control
          type="email"
          id={field}
          autoComplete={field}
          className="form-control form-control-lg rounded-0"
          {...register(field, {
            required: { value: required, message: "required-field-error" },
          })}
        />
      }
    />
  );
}

function NumberField<T extends FieldValues>({
  isLoading,
  field,
  localeKey,
  required = true,
  min,
  max,
}: LoadingProps<DefaultProps<T> & { min: number; max: number }>) {
  const { register } = useFormContext<T>();

  if (isLoading) {
    return <Field isLoading />;
  }

  return (
    <Field
      field={field}
      localeKey={localeKey}
      input={
        <Form.Control
          type="number"
          min={min}
          max={max}
          id={field}
          autoComplete={field}
          className="form-control form-control-lg rounded-0"
          {...register(field, {
            required: { value: required, message: "required-field-error" },
            valueAsNumber: true,
          })}
        />
      }
    />
  );
}

function PasswordField<T extends FieldValues>({
  isLoading,
  field,
  localeKey,
  required = true,
}: LoadingProps<DefaultProps<T>>) {
  const { register } = useFormContext<T>();

  if (isLoading) {
    return <Field isLoading />;
  }

  return (
    <Field
      field={field}
      localeKey={localeKey}
      input={
        <Form.Control
          type="password"
          id={field}
          autoComplete={field}
          className="form-control form-control-lg rounded-0"
          {...register(field, {
            required: { value: required, message: "required-field-error" },
          })}
        />
      }
    />
  );
}

function PhoneField<T extends FieldValues>({
  isLoading,
  field,
  localeKey,
  required = true,
}: LoadingProps<DefaultProps<T>>) {
  const { register } = useFormContext<T>();

  if (isLoading) {
    return <Field isLoading />;
  }

  return (
    <Field
      field={field}
      localeKey={localeKey}
      input={
        <Form.Control
          type="tel"
          id={field}
          autoComplete={field}
          className="form-control form-control-lg rounded-0"
          {...register(field, {
            required: { value: required, message: "required-field-error" },
          })}
        />
      }
    />
  );
}

function FileField<T extends FieldValues>({
  isLoading,
  field,
  localeKey,
  required = true,
}: LoadingProps<DefaultProps<T>>) {
  const { register } = useFormContext<T>();

  if (isLoading) {
    return <Field isLoading />;
  }

  return (
    <Field
      field={field}
      localeKey={localeKey}
      input={
        <input
          type="file"
          id={field}
          autoComplete={field}
          className="form-control w-full"
          accept=".jpeg,.jpg,.png,.dcm"
          {...register(field, {
            required: { value: required, message: "required-field-error" },
          })}
        />
      }
    />
  );
}

function TextAreaField<T extends FieldValues>({
  isLoading,
  field,
  localeKey,
  required = true,
}: LoadingProps<DefaultProps<T>>) {
  const { register } = useFormContext<T>();

  if (isLoading) {
    return <Field isLoading />;
  }

  return (
    <Field
      field={field}
      localeKey={localeKey}
      input={
        <Form.Area
          id={field}
          autoComplete={field}
          className="form-control form-control-lg rounded-0 w-full"
          {...register(field, {
            required: { value: required, message: "required-field-error" },
          })}
        />
      }
    />
  );
}

const SectionTitleStyled = styled.h5`
  color: var(--color-primary);
`;
const SectionTitle = ({ text }: { text: string }) => {
  return <SectionTitleStyled className="mt-4">{text}</SectionTitleStyled>;
};

Field.Text = TextField;
Field.Date = DateField;
Field.Dropdown = DropdownField;
Field.RadioField = RadioField;
Field.Email = EmailField;
Field.Number = NumberField;
Field.Password = PasswordField;
Field.Phone = PhoneField;
Field.File = FileField;
Field.TextArea = TextAreaField;
Field.SectionTitle = SectionTitle;

export default Field;
