import styled from "styled-components";

const Link = styled.a`
  display: flex;
  flex-direction: row;

  border: none;
  white-space: nowrap;

  height: 45px;
  cursor: pointer;

  /* Text styling */
  align-items: center;

  font-family: "Nunito";
  font-weight: 600;
  font-size: 16px;
  color: white;

  color: var(--color-blue);
  text-decoration: underline; /* Add underline */
  text-underline-offset: 4px;
`;

export default Link;
