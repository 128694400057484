import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

interface BackButtonProps {
  navUrl?: string;
  color?: string;
}
const BackButton = ({ navUrl, color }: BackButtonProps) => {
  const navigate = useNavigate();
  return (
    <FontAwesomeIcon
      icon={faChevronLeft}
      size="2x"
      onClick={() => navigate(navUrl ?? "/records")}
      style={{ cursor: "pointer", color: color ?? "var(--color-primary)" }}
    />
  );
};

export default BackButton;
