import { useTranslation } from "react-i18next";
import { TableDiv, TableHeader } from "../../../common/table/Table";
import RecordRow from "./RecordRow";
import { RecordDTO } from "../../../../api/data-contracts";
import { RoleTypeEnum } from "../../../../api/ExtraEnums";
import { useAuth } from "../../../../auth/AuthContext";

interface RecordsTableProps {
  records: RecordDTO[];
}

const RecordsTable = ({ records }: RecordsTableProps) => {
  const { t, ready, i18n } = useTranslation("records", {
    keyPrefix: "headers",
  });
  const { userInfo } = useAuth();
  const notReady = !ready;

  if (notReady) {
    return <></>;
  }

  const role = userInfo?.user?.role;
  return (
    <TableDiv>
      <TableHeader>
        <tr>
          <th>{t("title")}</th>
          <th>{t("diagnosis")}</th>
          <th>{t("surgery")}</th>
          <th>{t("creation-date")}</th>
          {(role === RoleTypeEnum.ADMIN || role === RoleTypeEnum.TEACHER) && (
            <th>{t("edit")}</th>
          )}
          <th>{t("view")}</th>
        </tr>
      </TableHeader>
      <tbody>
        {records.map((record, i) => (
          <RecordRow key={i} rowNum={i + 1} record={record} />
        ))}
      </tbody>
    </TableDiv>
  );
};

export default RecordsTable;
