export enum TableType {
  DIAGNOSIS = "diagnosis",
  DISCIPLINE = "discipline",
  GENDER = "gender",
  LOCATION = "location",
  SURGERY = "surgery",
}

export enum RoleTypeEnum {
  VIEWER = "viewer",
  TEACHER = "teacher",
  ADMIN = "admin",
}
