import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../auth/AuthContext";

const StyledNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--color-primary);
  color: white;

  ul {
    align-items: center;
    align-text: center;
    justify-content: justify-between;
    gap: 20px;
  }

  .left {
    display: flex;
    gap: 60px;
  }

  .right {
    gap: 20px;
  }
`;

const RightSideDiv = styled.div`
  position: fixed;
  text-align: center;
  width: 30%;
  height: 100%;

  top: 0px;
  right: 0px;
  background-color: #d61722;
  padding: 15px 25px;

  li {
    padding-top: 15px;
    justify-content: center;
  }

  ul {
    padding-top: 0px;
  }

  a {
    color: white !important;
  }
`;

const ImageDiv = styled.div`
  cursor: pointer;
`;

interface NavMenuProps {
  left: JSX.Element;
  right: JSX.Element;
}

const NavMenu = ({ left, right }: NavMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const leftLi = React.Children.toArray(left.props.children).map((a, index) => (
    <li key={index}>{a}</li>
  ));
  const rightLi = React.Children.toArray(right.props.children).map(
    (a, index) => <li key={index}>{a}</li>
  );

  const changeMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  let menuButton = (
    <button className={`text-white navText`} onClick={() => changeMenuOpen()}>
      <MenuSvgs isMenuOpen={isMenuOpen} />
    </button>
  );

  const logoClick = () => {
    if (isAuthenticated) {
      navigate("/records");
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <header className="w-full">
        <StyledNavbar className="py-4 px-3 md:px-10">
          <div className={"left"}>
            <div>
              <ImageDiv onClick={logoClick}>
                <img src="/images/OrtoDB.png" alt="Main orto db logo" />
              </ImageDiv>
            </div>
            <ul className={`hidden lg:flex`}>{leftLi}</ul>
          </div>

          <div className={`right hidden lg:block`}>
            <ul className="flex">{rightLi}</ul>
          </div>

          <div className={`${isMenuOpen ? "hidden" : "block"} lg:hidden`}>
            {menuButton}
          </div>

          <RightSideDiv
            className={`${isMenuOpen ? "block" : "hidden"} lg:hidden`}
          >
            {menuButton}
            <div>
              <ul>{leftLi}</ul>
              <ul>{rightLi}</ul>
            </div>
          </RightSideDiv>
        </StyledNavbar>
      </header>
    </>
  );
};

const MenuSvgs = ({ isMenuOpen }: { isMenuOpen: boolean }) => {
  return (
    <>
      <svg
        className={`h-6 w-6 fill-current ${isMenuOpen ? "hidden" : ""}`}
        viewBox="0 0 24 24"
        style={{ color: "white" }}
      >
        <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
      </svg>
      <svg
        className={`fill-current h-6 w-6 ${!isMenuOpen ? "hidden" : ""}`}
        viewBox="0 0 24 24"
        style={{ color: "white" }}
      >
        <path d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
      </svg>
    </>
  );
};

export default NavMenu;
