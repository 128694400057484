import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { recordApi } from "../../../../api";
import {
  PageResultDTO,
  RecordsFilterDTO,
} from "../../../../api/data-contracts";
import Filter, { emptyFilterValues } from "../../../common/Filter";
import PageSwitcher from "../../../common/PageSwitcher";
import RecordsTable from "./RecordsTable";
import { useTranslation } from "react-i18next";

const RecordsSection = () => {
  const storedFilter = localStorage.getItem("records_filter");
  const { i18n } = useTranslation(["records"]);

  const [filter, setFilter] = useState<RecordsFilterDTO>(
    storedFilter !== null && storedFilter !== undefined
      ? JSON.parse(storedFilter)
      : emptyFilterValues
  );

  const { isLoading, isError, error, data } = useQuery<
    PageResultDTO,
    AxiosError
  >({
    queryKey: ["records", filter],
    queryFn: () =>
      recordApi.findWithFilter(filter).then((resp) => {
        return resp.data;
      }),
  });

  const filterChanged = async (data: RecordsFilterDTO) => {
    localStorage.setItem("records_filter", JSON.stringify(data));
    data.language = i18n.language.toLocaleUpperCase();
    setFilter(data);
  };

  const changePage = (newPageNum: number) => {
    const newFilter = { ...filter, pageNum: newPageNum };
    setFilter(newFilter);
  };

  if (data?.totalPages && data.totalPages < filter.pageNum!) {
    changePage(data.totalPages);
  }

  return (
    <>
      <Filter initialValues={filter} onSubmit={filterChanged} />
      {isLoading ? (
        <p></p>
      ) : isError || error ? (
        <p>{"Error while loading th data: " + error.message}</p>
      ) : (
        <>
          <RecordsTable records={data.entities!} />
          <PageSwitcher
            currentPage={filter.pageNum!}
            totalPages={data.totalPages!}
            changePage={changePage}
          />
        </>
      )}
    </>
  );
};

export default RecordsSection;
