import Flag from "react-flagkit";
import { useTranslation } from "react-i18next";

const LanguageSwitch = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      {i18n.language === "en" ? (
        <Flag
          country="CZ"
          role="button"
          title={"CZ"}
          onClick={() => changeLanguage("cs")}
        />
      ) : (
        <Flag
          country="GB"
          role="button"
          title={"GB"}
          onClick={() => changeLanguage("en")}
        />
      )}
    </>
  );
};

export default LanguageSwitch;
